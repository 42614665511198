<script>
    import Financials from '@/services/Financials';
    import Swal from "sweetalert2";
    import Orders from '@/services/Orders';
    import validationMessages from '@/components/validations'
    import {minLength, numeric, required} from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        props: {
            transactionData: {
                default: null
            },
        },
        watch: {
            transactionData: function(newVal) {
                this.status_e = newVal.status;
            }
        },
        validations: {
            amount: {
                required,
                numeric
            },
            reason: {
                required,
                minLength: minLength(2)
            },
        },
        computed:{
            editTransactionTitle(){
                return 'Edit Transacation: ' + this.transactionData.transaction_id
            },
            oldStatusData(){
                return this.transactionData.status;
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                status_e: '',
                oldStatus: '',
                amount: '',
                reason: '',
                showModal: false,
                orderDetails:[],
                transactionDetails:[],
                tryingToEdit: false,
                submitted: false,
                submittedR: false,
            };
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async updateTransactionStatus(){

                this.tryingToEdit = true;
                this.submitted = true;

                try {
                    await Financials.updateTransaction({
                        transaction_id: this.transactionData.transaction_id,
                        status: this.status_e,
                        guid: this.transactionData.guid,
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        if (response.data) {
                            this.updateOrder()
                        } else {
                            this.failedmsg('Transaction Status not updated')
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async updateOrder(){
                try {
                    var oStatus = '';
                    if(this.status_e == 'processed' || this.status_e == 'processed_manually'){
                        oStatus = 'completed';
                    }else{
                        oStatus = this.status_e;
                    }
                    await Orders.updateOrder({
                        order_guid: this.transactionData.guid,
                        order:{
                            status : oStatus
                        },
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        this.successmsg(response);
                    }).catch(error => {
                        Financials.updateTransaction({
                            transaction_id: this.transactionData.transaction_id,
                            status: this.oldStatusData
                        }).then((response) => {
                        })
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.refreshData()
                        this.closeModal();
                        this.tryingToEdit = false;
                        this.submitted = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async refundTransaction(){
                this.tryingToEdit = true;
                this.submittedR = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Financials.createRefund({
                            transaction_id: this.transactionData.transaction_id,
                            order_type: this.transactionData.type,
                            amount: this.amount,
                            reason: this.reason,
                            guid: this.transactionData.guid,
                        }).then((response) => {
                            if(response.data){
                                this.status_e = response.data.transaction_status
                            }else{
                                this.status_e = 'refunded'
                            }
                            this.updateOrder()
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.amount = '';
                this.reason = '';
                this.submitted = false;
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="edit_transaction" :title="editTransactionTitle" title-class="font-18" centered>
        <div class="row mb-3" v-if="transactionData">

            <div class="col-sm-12">
                <h5>Change Status</h5>
                <form @submit.prevent="updateTransactionStatus">
                    <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                        <select v-model="status_e" class="form-select">
                            <option disabled value=" ">undefined</option>
                            <option value="processed">processed</option>
                            <option value="processed_manually">processed manually</option>
                            <option value="refunded">refunded</option>
                            <option value="partially_refunded">partially refunded</option>
                        </select>
                    </b-form-group>
                    <b-button variant="primary" @click="updateTransactionStatus" class="float-end" :disabled="tryingToEdit">
                        <b-spinner v-show="tryingToEdit" small></b-spinner>
                        Update Status
                    </b-button>
                    <input type="hidden" name="csrf_token" v-model="csrf_token">
                </form>
            </div>
            <div class="col-sm-12 mt-5">
                <h5>Refund Amount</h5>
                <form @submit.prevent="refundTransaction">
                    <b-form-group label="Amount" label-for="formrow-amount-input" class="mb-3">
                        <b-form-input
                                v-model.number="amount"
                                type="number"
                                id="amount"
                                aria-describedby="amount-feedback"
                                :class="{
                                  'is-invalid': submittedR && $v.amount.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submittedR" :fieldName="'Amount'" :validationName="$v.amount"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Reason" label-for="reason" class="mb-3">
                        <b-form-textarea
                                id="reason"
                                v-model="reason"
                                placeholder="Enter reason for refund"
                                rows="3"
                                max-rows="6"
                                :class="{
                                  'is-invalid': submittedR && $v.reason.$error,
                                }"
                        ></b-form-textarea>
                        <validationMessages v-if="submittedR" :fieldName="'Reason'" :validationName="$v.reason"></validationMessages>
                    </b-form-group>

                    <b-button variant="primary" @click="refundTransaction" class="float-end" :disabled="tryingToEdit">
                        <b-spinner v-show="tryingToEdit" small></b-spinner>
                        Refund</b-button>
                </form>
            </div>

        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>